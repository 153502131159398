import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { ManageAccountModal } from "@/game-destiny2/components/ManageAccountModal.jsx";
import definition from "@/game-destiny2/definition.mjs";
import Accounts from "@/hub-settings/Accounts.jsx";
import { PageContainer } from "@/hub-settings/Common.style.jsx";
import GearIcon from "@/inline-assets/gear.svg";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function AccountsDestiny2() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const destiny2 = state.settings.loggedInAccounts[definition.shortName];

  const [profileId, setProfileId] = useState<string>(null);

  const profiles = useMemo(
    () =>
      Object.entries(destiny2 ?? {}).map(([, profile]) => ({
        displayName: profile.bungieName,
        key: profile.bungieId,
        avatar: `${appURLs.BUNGIE}${profile.iconPath}`,
      })),
    [destiny2],
  );
  return (
    <PageContainer>
      <Accounts
        profiles={profiles}
        gameShort={definition.shortName}
        autoDetectText={[
          "destiny2:settings.autoDetectProfile",
          "Hey! I can automatically detect your profile. Just open Destiny 2 and log into the account you want to connect.",
        ]}
        renderOtherActionItems={(profile) => (
          <button
            onClick={() => setProfileId(profile.key)}
            style={{ backgroundColor: "transparent", cursor: "pointer" }}
          >
            <GearIcon />
            <span className="visually-hidden">{t("common:edit", "Edit")}</span>
          </button>
        )}
        renderOtherItems={undefined}
        showDeleteAction={false}
      />
      <ManageAccountModal
        show={!!profileId}
        profile={destiny2[profileId]}
        onClose={() => setProfileId(null)}
      />
    </PageContainer>
  );
}
