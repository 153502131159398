// EXEMPT
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.js";
import { Modal } from "clutch/src/Modal/Modal.js";

import { readState } from "@/__main__/app-state.mjs";
import { writeSettings } from "@/app/actions.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import AccountConnectionCard from "@/game-destiny2/components/AccountConnectionCard.jsx";
import {
  deleteDialogContainer,
  dialogContainer,
} from "@/game-destiny2/components/ManageAccountModal.style.jsx";
import type { UserProfile } from "@/game-destiny2/models/destiny2-user-profile.mjs";
import { oauthTokenHandler } from "@/game-destiny2/utils/auth-token-handler.mjs";
import { getBungieOAuthProps } from "@/game-destiny2/utils/redirect-to-bungie.mjs";
import DeleteIcon from "@/inline-assets/trash-icon.svg";
import { Avatar } from "@/shared/Avatar.jsx";
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogWindow,
} from "@/shared/Dialog.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const deleteType = {
  DELINK: 1,
  DEAUTH: 2,
};

export const ManageAccountModal = ({
  show,
  onClose,
  profile,
}: {
  show: boolean;
  onClose(): void;
  profile: UserProfile;
}) => {
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const {
    destiny2: { oauth },
  } = useSnapshot(readState);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteDialogType, setDeleteDialogType] = useState(deleteType.DELINK);

  useEffect(() => {
    if (show) modalRef.current.showModal();
    else modalRef.current.close();
  }, [show]);

  const bungieId = profile?.bungieId;
  const token = oauth[bungieId];

  const handleDiconnectClick = () => {
    // delete token
    setDeleteDialogType(deleteType.DEAUTH);
    setShowDeleteDialog(true);
  };

  const handleDelete = () => {
    setDeleteDialogType(deleteType.DELINK);
    setShowDeleteDialog(true);
  };

  const handleConsent = () => {
    setShowDeleteDialog(false);
    onClose?.();
    if (deleteDialogType === deleteType.DELINK) {
      // delete account
      writeSettings(["loggedInAccounts", "destiny2", bungieId]);
    }
    // delete token
    oauthTokenHandler.unsetToken(bungieId);
  };

  const DialogHeaderDeleteBtnView = () => (
    <button
      title={t("common:delete.account", "Delete Account")}
      type="button"
      className="icon-btn delete"
      onClick={handleDelete}
    >
      <DeleteIcon />
    </button>
  );

  return (
    <>
      <Modal ref={modalRef} onModalClose={onClose} className={dialogContainer}>
        <DialogWindow width={550}>
          <DialogContent>
            <DialogHeader
              title={t("common:manage.account", "Manage Account")}
              onPressClose={onClose}
              Following={<DialogHeaderDeleteBtnView />}
            />

            <DialogBody>
              <div className={"user-info"}>
                <Avatar
                  avatar={
                    profile?.iconPath && `${appURLs.BUNGIE}${profile.iconPath}`
                  }
                  avatarWidth={64}
                  variant="round"
                />
                {profile?.bungieName && (
                  <h3>
                    {profile.bungieName}
                    {profile.bungieNameCode && `#${profile.bungieNameCode}`}
                  </h3>
                )}
              </div>

              <AccountConnectionCard
                hasToken={!!token}
                title="Bungie"
                gameName="Bungie"
                onDisconnect={handleDiconnectClick}
                {...getBungieOAuthProps()}
              />
            </DialogBody>
          </DialogContent>
        </DialogWindow>
      </Modal>
      <DeleteAccountPromptDialog
        show={showDeleteDialog}
        title={t("common:delete.prompt.title", "Are you sure?")}
        text={
          deleteDialogType === deleteType.DELINK
            ? t(
                "common:delete.prompt.text",
                "This will de-link your Bungie account, Still want to continue?",
              )
            : t(
                "common:deauth.prompt.text",
                "This will revoke this account's authorization to Blitz. Still want to continue?",
              )
        }
        onConsent={handleConsent}
        onCancel={() => setShowDeleteDialog(false)}
      />
    </>
  );
};

export const DeleteAccountPromptDialog = ({
  title,
  text,
  show,
  onConsent,
  onCancel,
}) => {
  const { t } = useTranslation();
  const modalRef = useRef(null);
  useEffect(() => {
    if (show) modalRef.current.showModal();
    else modalRef.current.close();
  }, [show]);
  return (
    <Modal ref={modalRef} className={deleteDialogContainer}>
      <DialogWindow maxWidth={350} minWidth={270}>
        <DialogHeader showClose={false} title={title} />
        <DialogBody className={"body"}>
          <p>{text}</p>
        </DialogBody>
        <DialogFooter className={"footer"}>
          <Button emphasis="high" onClick={onConsent}>
            {t("common:btn.yes", "Yes")}
          </Button>
          <Button type="cancel" onClick={onCancel}>
            {t("common:cancel", "Cancel")}
          </Button>
        </DialogFooter>
      </DialogWindow>
    </Modal>
  );
};
