import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";

import BungieSVG from "@/inline-assets/bungie-logo.svg";
import { classNames } from "@/util/class-names.mjs";

const CardContainer = styled("div")`
  display: flex;
  padding: var(--sp-6);
  gap: var(--sp-6);
  background-color: #181b21;
  border-radius: var(--br-lg);
  .left-icon {
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 0.5rem;
    display: flex;
    padding: 0 6px;
    align-items: center;
    background-color: #000;
  }
  svg {
    width: var(--sp-18);
    height: auto;
  }
  .card-title {
    color: #e3e5ea;
    font-weight: 600;
  }
  .card-desc {
    color: #999ca2;
  }
  .card-btn {
    font-size: 1rem;
    font-weight: 600;
  }
`;

type ConnectionCardProps = {
  title?: string;
  desc?: string;
  gameName: string;
  hasToken: boolean;
  onDisconnect(): void;
  href: string;
  target?: string;
};

const getIcon = (gameName: string) => {
  let icon;
  switch (gameName) {
    case "Bungie": {
      icon = BungieSVG;
      break;
    }
    default:
      break;
  }
  return icon;
};

const AccountConnectionCard: React.FC<ConnectionCardProps> = ({
  title,
  desc,
  gameName,
  hasToken,
  onDisconnect,
  href,
  target,
}) => {
  const { t } = useTranslation();
  const Icon = getIcon(gameName);
  return (
    <CardContainer>
      {Icon && (
        <span className="left-icon">
          {" "}
          <Icon />{" "}
        </span>
      )}
      <div>
        {title && <p className="card-title">{title}</p>}
        <p className="card-desc">
          {desc ||
            t(
              "common:connect.game.account",
              "Connect your {{gameName}} account to access to all of Blitz’s features",
              { gameName },
            )}
        </p>
      </div>
      <Button
        {...classNames("card-btn")}
        emphasis={!hasToken ? "high" : "medium"}
        size={"large"}
        {...(hasToken ? { onClick: onDisconnect } : { href, target })}
      >
        {hasToken
          ? t("common:disconnect", "Disconnect")
          : t("common:connect", "Connect")}
      </Button>
    </CardContainer>
  );
};

export default AccountConnectionCard;
