import { css } from "goober";

export const dialogContainer = css`
  .delete svg {
    width: 1rem !important;
    height: 1rem !important;
  }
  .user-info {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    h3 {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
`;
export const deleteDialogContainer = css`
  .body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .footer {
    justify-content: space-between;
  }
`;
